import axios from "axios";
export const FILE_API_ENDPOINT = 'http://a3foods.enarxi.com:3023/';

// export const FILE_API_ENDPOINT = "http://18.61.183.168:3022/";
export const API_ENDPOINT = `${FILE_API_ENDPOINT}EXI/`;


export const getAxiosInstance = () => {
    var axiosInstance = axios.create({
        timeout: 1000 * 60
    });
    axiosInstance.defaults.baseURL = API_ENDPOINT;
    return axiosInstance;
};

var axiosInstance = getAxiosInstance();


export const getDetails = (param) => {
    return axiosInstance.get(`DeviceData${param ? `?${param}` : ``}`);
}