import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { getDetails } from './service';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';

function createData(
  name,
  calories,
  fat,
  carbs,
  protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];




function App() {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    getDetails().then((resp) => {
      console.log(resp.data);
      setData(resp.data[0]);
    })
  }, [setData])
  return (
    <Grid container spacing={2} alignItems={"center"} justifyContent={"center"}>
      <Grid item xl={12} xs={12} md={12} textAlign={"center"}>
        <Typography>LTI Lalgudi Plant {moment(data['updatedAt']).format("DD-MM-YYYY HH:mm:ss")}</Typography>
      </Grid>
      <Grid item>
        <TableContainer component={Paper}>
          <Table sx={{ width: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Plant</TableCell>
                <TableCell align="right">Species</TableCell>
                <TableCell align="right">Scientific Name</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data?.map((row, ind) => (
                <TableRow
                  key={ind}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {ind + 1}
                  </TableCell>
                  <TableCell align="right">{row.species}</TableCell>
                  <TableCell align="right">{row.scientific_name}</TableCell>
                  <TableCell align="right">{row.value.toFixed(3)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default App;
